import React from "react";
import styles from "./AdditionalInfoBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import ReactHtmlParser from 'react-html-parser';

class AdditionalInfoBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>{ this.props.additional.title }</BlockTitle>
                    <div data-aos="fade-down" className={styles.additionalContent}>
                        { ReactHtmlParser(this.props.additional.content) }
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default AdditionalInfoBlock;