import React from "react";
import styles from "./SystemConnectionCard.module.css";

function SystemConnectionCard(props){
    return (
        <div data-aos="fade-right" data-aos-delay={(props.cardIndex - 1) * 50}  className={styles.systemConnectionCard}>
            {props.children}
        </div>
    );
}

export default SystemConnectionCard;