import React from "react";
import styles from "./ResultBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";

class ResultBlock extends React.Component{
    render(){
        const { picture, list, listTitle} = this.props.results
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Итог</BlockTitle>
                    <div className={styles.wrap}>
                        <div data-aos="fade-up-right" className={styles.column}>
                            <div className={styles.picture}>
                                <img src={picture} alt=""/>
                            </div>
                        </div>
                        <div data-aos="fade-down-left" className={styles.column}>
                            <div className={styles.list}>
                                <div className={styles.listTitle}>{ listTitle }</div>
                                {list.map((item, i) =>
                                    <div className={styles.listItem} key={i}>{item}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default ResultBlock;