import React from "react";
import Button from "../../atoms/Button";
import styles from "./Tariffs.module.css";
import tariffs from '../../../static/json/tariffs';


class Tariffs extends React.Component {
    activeTariff() {
        return tariffs.find(tariff => tariff.category === this.props.tariff.category)
    }
    render() {
        return (
        <div className={styles.tariffs}>
            { this.activeTariff().items.map((t, i) =>
            <div data-aos="fade-right" data-aos-delay={i * 100} className={`${styles.tariffsItem} ${t.price === '' ? styles.tariffsItemAdditional : ''}`} key={i}>
                <div className={styles.tariffsItemBody}>
                    <div className={styles.tariffsItemName}>{t.name}</div>
                    {t.price && <div className={styles.tariffsItemPrice}>
                        <strong>{t.price}</strong> <span className={styles.tariffsItemPriceCurrency}>руб / месяц</span>
                    </div>}
                    <div className={styles.tariffsItemList}>
                        {t.list.map(li =>
                            <div className={styles.tariffsItemListItem} key={li}>{li}</div>
                        )}
                    </div>
                </div>
                {t.price && <Button role="primary" onClick={() => { this.props.handleContactFormToggle() }} className={styles.tariffsItemButton}>Консультация по тарифу</Button>}
            </div>
            )}
        </div>
        );
    }
};

export default Tariffs;
