import React from "react";
import styles from "./TaskBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";

class TaskBlock extends React.Component{
    render(){
        const { title, subtitle, list } = this.props.case.tasks;
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>{ title }</BlockTitle>
                    { subtitle && <BlockParagraph data-aos="fade-up" centerDesktop={true}>{ subtitle }</BlockParagraph> }
                    <div className={styles.list}>
                        {list.map((task, i) =>
                            <div data-aos="zoom-in" data-aos-delay={i*100} className={styles.listItem} key={i}>
                                <div className={styles.listItemNum}>
                                    {i+1}
                                </div>
                                <div className={styles.listItemContent}>
                                    {task}
                                </div>
                            </div>
                        )}
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default TaskBlock;