import React from "react";
import styles from "./IntroductionBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import InfrastructureImage from "../../atoms/images/InfrastructureImage";
import IntroductionForm from "../../molecules/IntroductionForm";
import ScrollArrowImage from "../../atoms/images/ScrollArrowImage";
import ScrollLink from "../../atoms/ScrollLink/ScrollLink";


class IntroductionBlock extends React.Component{
    render(){
        return (
            <CenteredContainer>
                <div className={styles.introductionBlockTopContent}>
                    <InfrastructureImage />
                    <IntroductionForm />
                </div>
                <div className={styles.introductionBlockBottomContent}>
                    <ScrollLink to="#capabilities" smooth>
                        <ScrollArrowImage/>
                    </ScrollLink>
                </div>
            </CenteredContainer>
        );
    }
}

export default IntroductionBlock;