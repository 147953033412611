import React from "react";
import styles from "./BlockParagraph.module.css";

function BlockParagraph(props){
    return <p data-aos="fade-down"  className={`${styles.blockParagraph} ${props.centerDesktop ? styles.textCenteredDesktop : ''} ${props.centerMobile ? styles.textCenteredMobile : ''}`}
           style={props.style}>
           {props.children}
    </p>;

}
export default BlockParagraph;