import React from "react";

function InstagramLogo(props) {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.4805 0H5.51913C2.47588 0 0 2.476 0 5.51924V14.4806C0 17.524 2.47588 19.9999 5.51913 19.9999H14.4805C17.524 19.9999 19.9999 17.5239 19.9999 14.4806V5.51924C20 2.476 17.524 0 14.4805 0ZM18.2255 14.4806C18.2255 16.5455 16.5455 18.2254 14.4806 18.2254H5.51913C3.45433 18.2255 1.77449 16.5455 1.77449 14.4806V5.51924C1.77449 3.45445 3.45433 1.77449 5.51913 1.77449H14.4805C16.5454 1.77449 18.2254 3.45445 18.2254 5.51924V14.4806H18.2255Z" fill="black"/>
            <path d="M10.0001 4.84671C7.15848 4.84671 4.84668 7.15851 4.84668 10.0002C4.84668 12.8417 7.15848 15.1534 10.0001 15.1534C12.8418 15.1534 15.1536 12.8417 15.1536 10.0002C15.1536 7.15851 12.8418 4.84671 10.0001 4.84671ZM10.0001 13.3788C8.13705 13.3788 6.62117 11.8631 6.62117 10.0001C6.62117 8.13684 8.13693 6.62108 10.0001 6.62108C11.8634 6.62108 13.3791 8.13684 13.3791 10.0001C13.3791 11.8631 11.8632 13.3788 10.0001 13.3788Z" fill="black"/>
            <path d="M15.3696 3.34207C15.0278 3.34207 14.6919 3.48048 14.4505 3.72299C14.2078 3.96432 14.0684 4.30029 14.0684 4.64336C14.0684 4.98536 14.208 5.32122 14.4505 5.56373C14.6918 5.80506 15.0278 5.94465 15.3696 5.94465C15.7127 5.94465 16.0475 5.80506 16.29 5.56373C16.5325 5.32122 16.6709 4.98525 16.6709 4.64336C16.6709 4.30029 16.5325 3.96432 16.29 3.72299C16.0487 3.48048 15.7127 3.34207 15.3696 3.34207Z" fill="black"/>
        </svg>
    );
}

export default InstagramLogo;