import React from "react";
import styles from "./NavigationList.module.css";
function NavigationList(props){
    return (
        <div className={`${styles.navigationList} ${props.isExpanded ? styles.menuExpanded : styles.menuCollapsed}`}>
            {props.children}
        </div>
    );
}

export default NavigationList;