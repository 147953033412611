import React from "react";

function PlusIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path d="M1220.5,3516.5v-6h3v6h6v3h-6v6h-3v-6h-6v-3h6Zm1.5,16.5a15,15,0,1,1,15-15A15.005,15.005,0,0,1,1222,3533Zm0-3a12,12,0,1,0-12-12A12,12,0,0,0,1222,3530Z" transform="translate(-1207 -3503)"/>
        </svg>
    );
}

export default PlusIcon;