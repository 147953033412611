import React from "react";
import styles from "./LogoLoader.module.css";
function LogoLoader(props){
  return (
      <svg xmlns="http://www.w3.org/2000/svg" width="104" height="36" viewBox="-1 -1 84 32">
          <path className={styles.logo}
                d="M477.356,93.02a4.6,4.6,0,0,1,1.822.373,1.941,1.941,0,1,0,2.468,2.559,4.5,4.5,0,0,1,.3,1.625A4.591,4.591,0,1,1,477.356,93.02ZM420.273,82.993c0.053,0,4.978-.149,8.155,1.422v8.531c-1.851-2.443-5.484-3.117-7.336-3.117A10.557,10.557,0,0,0,417,90.5a7.137,7.137,0,0,0-3.92,3.974,7.8,7.8,0,0,0-.568,2.989,7.89,7.89,0,0,0,.568,3.025,7.3,7.3,0,0,0,3.938,4.011,11.815,11.815,0,0,0,3.728.69,10.638,10.638,0,0,0,7.684-3.024v8.458A22.494,22.494,0,0,1,420.273,112a18.356,18.356,0,0,1-6.242-1.062,14.322,14.322,0,0,1-4.617-3.026,15.041,15.041,0,0,1-3.2-4.63,14.782,14.782,0,0,1-.019-11.666,14.385,14.385,0,0,1,7.822-7.547A18.432,18.432,0,0,1,420.273,82.993Zm56.572,29A22.494,22.494,0,0,0,485,110.626v-8.458a10.638,10.638,0,0,1-7.684,3.024,11.81,11.81,0,0,1-3.728-.69,7.3,7.3,0,0,1-3.938-4.011,7.89,7.89,0,0,1-.568-3.025,7.8,7.8,0,0,1,.568-2.989,7.137,7.137,0,0,1,3.92-3.974,10.557,10.557,0,0,1,4.094-.674c1.852,0,5.485.674,7.336,3.117V84.415c-3.178-1.571-8.1-1.422-8.155-1.422a18.432,18.432,0,0,0-6.261,1.075,14.385,14.385,0,0,0-7.822,7.547,14.782,14.782,0,0,0,.019,11.666,15.041,15.041,0,0,0,3.2,4.63,14.318,14.318,0,0,0,4.616,3.026A18.361,18.361,0,0,0,476.845,112Zm-27.368-.745,11.659-27.494h-7.81L446.617,100.5l-6.673-16.737h-7.809l11.622,27.494h5.72Z"
                transform="translate(-405 -83)"/>
      </svg>
  );
}

export default LogoLoader;