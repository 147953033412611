import React from "react";
import styles from "./DataProtectionBanner.module.css";
import ShieldAndServer from "../../atoms/images/SystemSchemeIcons/ShieldAndServer";
import BlockParagraph from "../../atoms/BlockParagraph";
import BlockTitle from "../../atoms/BlockTitle";

function DataProtectionBanner(props){
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}  data-aos="zoom-in">
                <ShieldAndServer/>
            </div>
            <div className={styles.textContainer}>
                <BlockTitle>Надежная защита <br/> персональных данных</BlockTitle>
                <BlockParagraph>Для нас очень важны данные клиента. <br/> Мы подписываем договор
                    о неразглашении информации (NDA). <br/> Результаты анализа хранятся
                    в зашифрованном виде в базе данных, <br/> доступ предоставляется
                    только доверенному кругу лиц заказчика.</BlockParagraph>
            </div>
        </div>
    );
}

export default DataProtectionBanner;