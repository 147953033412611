import React from "react";
import styles from "./FullReportingCardsList.module.css";
import FullReportingCard from "../FullReportingCard/FullReportingCard";
import MobileIcon from "../../atoms/images/FullReportingIcons/MobileIcon";
import ReportIcon from "../../atoms/images/FullReportingIcons/ReportIcon";
import InfrastructureIcon from "../../atoms/images/FullReportingIcons/InfrastructureIcon";
import SliderIndicators from "../SliderIndicators";

class FullReportingCardsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scrollEventHandler: null,
            totalAmount: 3
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }

    render(){
        return (
            <div className={styles.outerWrapper}>
                <div className={styles.cardsContainer} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                    <FullReportingCard type="white" cardIndex={1}>
                        <MobileIcon/>
                        <h3>Мобильная версия</h3>
                        <p>Сложность системы сведена
                            к минимуму, все данные круглосуточно доступны
                            в вашем телефоне</p>
                    </FullReportingCard>
                    <FullReportingCard type="orange" cardIndex={2}>
                        <ReportIcon/>
                        <h3>Удобные отчеты</h3>
                        <p>Предоставление отчетов
                            в графическом виде, удобном
                            для восприятия и аналитики</p>
                    </FullReportingCard>
                    <FullReportingCard type="blue" cardIndex={3}>
                        <InfrastructureIcon/>
                        <h3>Интеграции со сторонними
                            системами</h3>
                        <p>Мы обеспечиваем интеграцию
                            с любым кассовым или складским программным обеспечением</p>
                    </FullReportingCard>
                    <div className={styles.mock}/>
                </div>
                <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
            </div>
        );
    }
}

export default FullReportingCardsList;