import React from "react";

function FacebookLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="15" viewBox="0 0 8 15">
            <path fill="black"
                  d="M1376.1,108h-3.27v-7.5h-1.84V97.872h1.84v-2.25A2.767,2.767,0,0,1,1375.45,93h3.14v2.625h-1.83a0.558,0.558,0,0,0-.53.5v1.75h2.76a17,17,0,0,0-.4,2.625h-2.49V108Z"
                  transform="translate(-1371 -93)"/>
        </svg>
    );
}

export default FacebookLogo;