import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import IntroductionBlock from "../../organisms/IntroductionBlock";
import TheftBlock from "../../organisms/TheftBlock";
import PrincipleOperationBlock from "../../organisms/PrincipleOperationBlock";
import SystemCapabilitiesBlock from "../../organisms/SystemCapabilitiesBlock";
// import SystemSchemeBlock from "../../organisms/SystemSchemeBlock";
import AdvantagesBlock from "../../organisms/AdvantagesBlock";
import CasesBlock from "../../organisms/CasesBlock";
import HistorySuccessBlock from "../../organisms/HistorySuccessBlock";
import FaqBlock from "../../organisms/FaqBlock";
import InvestmentBlock from "../../organisms/InvestmentBlock";
import PriceBlock from "../../organisms/PriceBlock";
import ClientsBlock from "../../organisms/ClientsBlock";
import VisibleConfusionBlock from "../../organisms/VisibleConfusionBlock";
import ContactsBlock from "../../organisms/ContactsBlock";
import Footer from "../../organisms/Footer";
// import DataProtectionBlock from "../../organisms/DataProtectionBlock";

class HomePageTemplate extends React.Component{
    render(){
        return (
            <div className="wrap">
                <NavigationBar />
                <IntroductionBlock />
                <TheftBlock />
                <PrincipleOperationBlock />
                <SystemCapabilitiesBlock/>
                <AdvantagesBlock />
                {/* <SystemSchemeBlock/> */}
                {/* <DataProtectionBlock/> */}
                <CasesBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <HistorySuccessBlock />
                <FaqBlock />
                <InvestmentBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <PriceBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <ClientsBlock />
                <VisibleConfusionBlock/>
                <ContactsBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <Footer/>
            </div>
        );
    }
}

export default HomePageTemplate;