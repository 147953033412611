import React from "react";
import styles from "./FaqBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import ScrollLink from "../../atoms/ScrollLink";

class FaqBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true} style={{ marginBottom: '60px' }}>Мы знаем, о чем вы подумали…</BlockTitle>
                    <div className={styles.list}>
                        <div data-aos="fade-left" className={styles.listItem}>
                            <div className={styles.listItemQuestion}>
                                Это дорого и окупится через 10 лет
                            </div>
                            <div className={styles.listItemAnswer}>
                                У большинства клиентов система окупается уже в первые 1-3 месяца. Чем крупнее предприятие и чем больше нарушений на нем случается, тем быстрее окупаются инвестиции в CVC. Подробнее читайте в <ScrollLink to="/#history" smooth>кейсах</ScrollLink>.
                            </div>
                        </div>
                        <div data-aos="fade-right" className={styles.listItem}>
                            <div className={styles.listItemQuestion}>
                                Внедрить CVC – долго и трудно
                            </div>
                            <div className={styles.listItemAnswer}>
                                Мы стараемся уложиться в несколько дней, причем все основные действия по интеграции мы берем на себя.
                            </div>
                        </div>
                        <div data-aos="fade-left" className={styles.listItem}>
                            <div className={styles.listItemQuestion}>
                                Это обычное видеонаблюдение
                            </div>
                            <div className={styles.listItemAnswer}>
                                Наш продукт — система аналитики, которая подключается к вашим камерам. Мы не заменяем камеру, мы заменяем команду аналитиков, которая 24 на 7 следит за всем происходящим и готовит удобные отчеты для вас.
                            </div>
                        </div>
                        <div data-aos="fade-right" className={styles.listItem}>
                            <div className={styles.listItemQuestion}>
                                Мы такое уже где-то видели
                            </div>
                            <div className={styles.listItemAnswer}>
                                Технология CVC уникальна (мы в процессе получения патента) и превосходит любые аналоги по точности идентификации событий (99,7%). У большинства конкурентов:
                                <ul>
                                    <li>Не предусмотрено выполнение большого количества сценариев событий одновременно</li>
                                    <li>Шаблонный продукта без учета особенностей заказчика</li>
                                    <li>Отсутствует гибкая настройка, возможность кастомизации под задачи заказчика</li>
                                </ul>
                            </div>
                        </div>
                        <div data-aos="fade-left" className={styles.listItem}>
                            <div className={styles.listItemQuestion}>
                                Могут быть проблемы с персональными данными
                            </div>
                            <div className={styles.listItemAnswer}>
                                Мы подписываем договор о неразглашении информации (NDA). Результаты анализа хранятся в зашифрованном виде в базе данных,  доступ предоставляется только доверенному кругу лиц заказчика.
                            </div>
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default FaqBlock;