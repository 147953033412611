import React from "react";

function IndustrialIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="27" viewBox="0 0 33 27">
            <path d="M853.5,4313.5H858v3h-1.5V4333H858v3H825v-3h1.5v-16.5H825v-3h4.5v-3a1.5,1.5,0,0,1,1.5-1.5h21a1.5,1.5,0,0,1,1.5,1.5v3Zm0,3h-24V4333H834v-10.5h3V4333h3v-10.5h3V4333h3v-10.5h3V4333h4.5v-16.5Zm-21-4.5v1.5h18V4312h-18Z" transform="translate(-825 -4309)"/>
        </svg>
    );
}

export default IndustrialIcon;