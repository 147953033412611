import React from "react";
import styles from "./SolutionCapabilitiesBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockParagraph from "../../atoms/BlockParagraph";
import BlockTitle from "../../atoms/BlockTitle";
import CapabilitiesCategory from "../../molecules/CapabilitiesCategory";

class SolutionCapabilitiesBlock extends React.Component{
    render(){
        return (
            <div className={styles.solutionCapabilitiesBlock} id="solution-capabilities">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Возможности системы</BlockTitle>
                    <BlockParagraph centerDesktop={true} style={{maxWidth: '100%', margin: 'auto'}}>
                        В основе программного обеспечения лежит комплекс алгоритмов машинного зрения, позволяющих вести видеомониторинг и производить анализ данных без прямого участия человека
                    </BlockParagraph>
                    <div className={styles.capabilitiesContainer}>
                        {this.props.solution.capabilities.map((category, key) => {
                            return <CapabilitiesCategory category={category} key={key}/>;
                        })}
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default SolutionCapabilitiesBlock;