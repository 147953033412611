import cases from '../static/json/cases';

let instance = null;

class Cases {
    constructor() {
        if(!instance){
            instance = this;
        }
        this.casesArray = cases ? cases : [];
        return instance;
    }

    get(){
        return [...this.casesArray];
    }

    getByAlias(alias){
        alias = alias ? alias.trim() : false;
        const foundCase = this.casesArray.find(c => c.alias === alias);
        return foundCase ? foundCase : false;
    }
}

export default Cases;