import React from "react";

function HeartIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32.281" height="29.87" viewBox="0 0 32.281 29.87">
            <path d="M841.074,4646.99l-13.8-13.77a9.759,9.759,0,0,1,13.8-13.72,9.743,9.743,0,0,1,13.782,13.7Zm11.5-15.74a6.748,6.748,0,0,0-9.541-9.49l-1.963,1.69-1.962-1.69a6.748,6.748,0,0,0-9.542,9.49l0.172,0.2,11.332,11.3,11.333-11.3Z" transform="translate(-824.938 -4617.13)"/>
        </svg>
    );
}

export default HeartIcon;