import React from "react";
import styles from "./YouTube.module.css";
import YouTube from 'react-youtube';
 
class Tube extends React.Component {
  render() {
    const opts = {
      playerVars: {
        // autoplay: 1,
        rel: 0,
      },
    };

    const videoId = this.props.videoId;
 
    return <YouTube className={styles.iframe} videoId={videoId} opts={opts} onReady={this._onReady} />;
  }
 
  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default Tube;