import React from "react";
import styles from "./VisibleConfusionBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import WorkflowCardsList from "../../molecules/WorkflowCardsList";

class VisibleConfusionBlock extends React.Component{
    render(){
        return (
            <div className={styles.container} id="visible-confusion">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Запуск системы в три шага</BlockTitle>
                    <BlockParagraph centerDesktop={true}>Все очень просто. Позвоните нам, чтобы познакомиться и организовать встречу</BlockParagraph>
                    <WorkflowCardsList/>
                </CenteredContainer>
            </div>
        );
    }
}

export default VisibleConfusionBlock;