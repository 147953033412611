import React from "react";
import styles from "./HistorySuccessBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import ScrollLink from "../../atoms/ScrollLink";
import cases from '../../../static/json/cases';

import Carousel from "../../molecules/Carousel";
import { SwiperSlide } from 'swiper/react';

class HistorySuccessBlock extends React.Component{
    render(){
        return (
            <div id="history" className={styles.container} style={{ backgroundColor: this.props.backgroundColor }}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Истории успеха</BlockTitle>
                    <div className={styles.list} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                        <Carousel slidesPerView={3} style={{ padding: '0 0 40px' }}>
                            {cases.map((item) =>
                                <React.Fragment key={item.alias}>
                                    { this.props.alias !== item.alias &&
                                        <SwiperSlide key={item.alias}>
                                            <div className={styles.listItem} key={item.alias} data-aos="zoom-in">
                                                <div className={styles.listItemHeader}>
                                                    <ScrollLink to={`/cases/${item.alias}/#top`} navigateTop className={styles.listItemThumbnail}>
                                                        <img src={item.introBg} alt={item.title} />
                                                    </ScrollLink>
                                                </div>
                                                <div className={styles.listItemBody}>
                                                    <ScrollLink to={`/cases/${item.alias}/#top`} navigateTop className={styles.listItemTitle}>
                                                        {item.title}
                                                    </ScrollLink>
                                                    <div>
                                                        <ScrollLink to={`/cases/${item.alias}/#top`} navigateTop className={styles.listItemLink}>
                                                            Читать кейс
                                                        </ScrollLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    }
                                </React.Fragment>
                            )}
                        </Carousel>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default HistorySuccessBlock;