import React from "react";
import styles from "./TheftBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";

class TheftBlock extends React.Component{
    render(){
        return (
            <div id="theft" className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Вы ежедневно теряете клиентов и деньги из-за...</BlockTitle>
                    <div className={styles.theftSection}>
                        <div data-aos="fade-right" className={styles.theftSectionItem}>
                            <div className={styles.theftSectionItemTitle}>
                                Воровства
                            </div>
                            <div className={styles.theftSectionItemGif}>
                                <video autoPlay="autoplay" loop="loop" muted="muted">
                                    <source src="/video/theft/1.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                        <div data-aos="fade-left" className={styles.theftSectionItem}>
                            <div className={styles.theftSectionItemTitle}>
                                Нарушений правил и норм
                            </div>
                            <div className={styles.theftSectionItemGif}>
                                <video autoPlay="autoplay" loop="loop" muted="muted">
                                    <source src="/video/theft/2.mp4" type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                    <div className={styles.desc}>
                        <BlockParagraph centerDesktop={true} style={{maxWidth: '100%'}}>Вы не сможете изменить ситуацию, если будете полагаться на человека,<br /> даже если он будет оснащен самыми лучшими камерами и записью.</BlockParagraph>
                        <BlockParagraph centerDesktop={true} style={{maxWidth: '100%'}}>CVC – нейронная сеть, которая видит нарушения и предоставляет вам<br /> заключения для принятия управленческих решений.</BlockParagraph>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default TheftBlock;