import React from "react";
import styles from "./CustomerBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import ReactHtmlParser from 'react-html-parser';

class CustomerBlock extends React.Component{
    render(){
        const { customer } = this.props
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Заказчик</BlockTitle>
                    <div className={styles.content}>
                        <div data-aos="fade-right" className={styles.item}>
                            <img className={styles.customerPhoto} src={customer.picture} alt=""/>
                        </div>
                        <div data-aos="fade-left" className={styles.item}>
                            <div className={styles.infoSection}>
                                { ReactHtmlParser(customer.desc) }
                            </div>
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default CustomerBlock;