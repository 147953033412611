import React from "react";
import styles from "./CloseButton.module.css";
function CloseButton(props){
    return <button className={styles.closeButton} onClick={props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 9.94 9.907">
            <path d="M1029.28,111.633l3.86-3.858,1.1,1.1-3.86,3.859,3.86,3.858-1.1,1.1-3.86-3.858-3.86,3.858-1.1-1.1,3.86-3.858-3.86-3.859,1.1-1.1Z"
                  transform="translate(-1024.31 -107.781)"/>
        </svg>
    </button>;
}

export default CloseButton;