import React from "react";
import styles from "./FormInputPhone.module.css";
class FormInputPhone extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            prevFormattedValueLength: 0
        };
        this.inputRef = React.createRef();
    }

    render() {
        return <div className={`${styles.inputWrapper} ${this.props.valueIsValid ? '' : styles.invalidValue} ${this.state.isFocused ? styles.focused : ''}`}>
            <span className={styles.countryCode} onClick={() => {this.inputRef.current.focus();}}>+375</span>
            <input ref={this.inputRef}  value={this.props.value} id={this.props.id} onChange={(event) => {
                this.processInputEvent(event)
            }} maxLength="12" placeholder="XX XXX XX XX" onFocus={() => {this.toggleFocus(true)}} onBlur={() => {this.toggleFocus(false)}}/>
        </div>;
    }

    toggleFocus(isFocused){
        this.setState({
            prevFormattedValueLength: this.state.prevFormattedValueLength,
            isFocused: isFocused
        });
    }

    processInputEvent(event) {
        this.handleFormatting(event);
        this.props.onInput(event);
    }

    handleFormatting(event){
        const inputElement = event.target;
        let caretStartBeforeFormat = inputElement.selectionStart;
        let caretEndBeforeFormat = inputElement.selectionEnd;
        let valueLengthBeforeFormat = inputElement.value.length;

        let formattedValue = this.formatValue(inputElement.value);
        inputElement.value = this.valueShouldBeTrimmed(valueLengthBeforeFormat, caretStartBeforeFormat) ? formattedValue.trim() : formattedValue;

        if (this.caretShouldBeMovedToPreviousPosition(valueLengthBeforeFormat, caretStartBeforeFormat)){
            inputElement.selectionStart = caretStartBeforeFormat;
            inputElement.selectionEnd = caretEndBeforeFormat;
        }
        this.setState({prevFormattedValueLength: formattedValue.length});
    }

    isAdding(prevLength, currentLength){
        return currentLength > prevLength;
    }

    valueShouldBeTrimmed(inputValueLength, oldSelectionStart){
        const isAdding = this.isAdding(this.state.prevFormattedValueLength,inputValueLength);
        const caretAtTheEnd = oldSelectionStart === inputValueLength;
        return isAdding && !caretAtTheEnd || !isAdding && caretAtTheEnd;
    }

    caretShouldBeMovedToPreviousPosition(inputValueLength, oldSelectionStart){
        const isAdding = this.isAdding(this.state.prevFormattedValueLength,inputValueLength);
        const caretAtTheEnd = oldSelectionStart === inputValueLength;
        return isAdding && !caretAtTheEnd || !isAdding;
    }

    formatValue(valueFromInput){
        let rawValue = valueFromInput.replace(/[^\d]/g, '');
        rawValue = rawValue.length > 9 ? rawValue.slice(0, 9) : rawValue;
        return rawValue.split('').reduce((prevChar, currChar, index) => {
            const whiteSpaceNeeded = (index === 2 || index === 5 || index === 7);
            return whiteSpaceNeeded ? `${prevChar} ${currChar}` : prevChar + currChar;
        }, '');
    }
}

export default FormInputPhone;