import React from "react";
import CasePageTemplate from "../../templates/CasePageTemplate";
import NotFoundPageTemplate from "../../templates/NotFoundPageTemplate";
import Cases from "../../../providers/Cases";

const Case = (props) =>  {
    const cases = new Cases();
    const caseAlias = props.match.params.caseAlias;
    let caseItem = cases.getByAlias(caseAlias);
    if (!caseItem){
        return <NotFoundPageTemplate handleContactFormToggle={props.handleContactFormToggle} />
    } else {
        return <CasePageTemplate case={caseItem} handleContactFormToggle={props.handleContactFormToggle} />
    }
};

export default Case;