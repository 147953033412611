import React from "react";
import styles from "./DropdownList.module.css";

class DropdownList extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            isCollapsed: true,
        }
    }

    componentDidMount() {
        this.setState({
           isCollapsed: this.state.isCollapsed,
           externalMouseDownListener: this.handleOutsideClick.bind(this),
           itemMouseUpListener: this.handleDropdownItemClick.bind(this)
        }, () => {
            document.addEventListener('mousedown', this.state.externalMouseDownListener);
            document.addEventListener('mouseup', this.state.itemMouseUpListener);
        });
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown',this.state.externalMouseDownListener);
        document.removeEventListener('mouseup',this.state.itemMouseUpListener);
    }

    handleOutsideClick(event){
        const isNotDropdownOrItsChildren = event.target !== this.itemsList && !this.itemsList.contains(event.target);
        const isNotTriggerButtonOrItsChildren = event.target !== this.label && !this.label.contains(event.target);
        if (this.label && isNotDropdownOrItsChildren && isNotTriggerButtonOrItsChildren){
            this.setState({
                isCollapsed: true
            });
        }
    }

    handleDropdownItemClick(event){
        const isDropdownDescendant = event.target && this.itemsList.contains(event.target);
        if (isDropdownDescendant){
            this.setState({
                isCollapsed: true
            });
        }
    }

    toggleList(){
        this.setState({
            isCollapsed: !this.state.isCollapsed
        });

        if (this.state.isCollapsed){
            this.itemsList.focus();
        }
    }

    render() {
        return (
            <div className={`${styles.dropdownList} ${this.state.isCollapsed ? styles.collapsed : styles.shown}`}>
                <div ref={element => {this.label = element}} className={styles.label} onClick={() => {this.toggleList()}}>
                    {this.props.label}
                    <span className={styles.stateIndicator}/>
                </div>
                <div ref={(element) => {this.itemsList = element}} className={styles.items}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default DropdownList;