import React from "react";

function HotelIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="31.97" viewBox="0 0 27 31.97">
            <path d="M467.5,4337.15h-3v-1.52h-12v1.52h-3v-1.52H448a3.019,3.019,0,0,1-3-3.04v-19.8a3.019,3.019,0,0,1,3-3.04h4.5v-3.04a1.512,1.512,0,0,1,1.5-1.53h9a1.512,1.512,0,0,1,1.5,1.53v3.04H469a3.019,3.019,0,0,1,3,3.04v19.8a3.019,3.019,0,0,1-3,3.04h-1.5v1.52Zm1.5-24.36H448v19.8h21v-19.8Zm-13.5,3.05v13.7h-3v-13.7h3Zm9,0v13.7h-3v-13.7h3Zm-3-7.61h-6v1.52h6v-1.52Z" transform="translate(-445 -4305.19)"/>
        </svg>
    );
}

export default HotelIcon;