import React from "react";

function PhoneIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
            <path d="M744,5870.42v3.54a0.992,0.992,0,0,1-.93.99c-0.437.03-.794,0.05-1.07,0.05a16,16,0,0,1-16-16c0-.28.015-0.63,0.046-1.07a1,1,0,0,1,1-.93h3.536a0.5,0.5,0,0,1,.5.45c0.023,0.23.044,0.41,0.064,0.55a13.793,13.793,0,0,0,1.207,4,0.459,0.459,0,0,1-.147.57l-2.157,1.54a12.986,12.986,0,0,0,6.843,6.84l1.54-2.15a0.471,0.471,0,0,1,.573-0.15,14,14,0,0,0,4,1.21q0.208,0.03.55,0.06a0.5,0.5,0,0,1,.449.5h0Z" transform="translate(-726 -5857)"/>
        </svg>
    );
}

export default PhoneIcon;