import React from "react";
import SolutionPageTemplate from "../../templates/SolutionPageTemplate";
import NotFoundPageTemplate from "../../templates/NotFoundPageTemplate";
import Solutions from "../../../providers/Solutions";

const   Solution = (props) =>  {
    const solutions = new Solutions();
    const solutionAlias = props.match.params.solutionAlias;
    let solution = solutions.getByAlias(solutionAlias);
    if (!solution){
        return <NotFoundPageTemplate  handleContactFormToggle={props.handleContactFormToggle} />
    }else{
        return <SolutionPageTemplate solution={solution}  handleContactFormToggle={props.handleContactFormToggle} />;
    }
};

export default Solution;