import React from "react";
import styles from "./CaseCardsList.module.css";
import CaseCard from "../CaseCard";
import PlusIcon from "../../atoms/images/CaseIcons/PlusIcon";
import Solutions from "../../../providers/Solutions";
import SliderIndicators from "../SliderIndicators";

class CaseCardsList extends React.Component{
    constructor(props){
        super(props);
        this.solutions = new Solutions();
        this.state = {
            scrollEventHandler: null,
            totalAmount: this.solutions.get().length + 1
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }

    render(){
        return (
            <div className={styles.outerWrapper}>
                <div className={styles.container} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                    {this.solutions.get().map((solution, key) => {
                        return (
                            <CaseCard key={key} cardIndex={key} to={`/solutions/${solution.alias}#top`} backgroundImage={`${process.env.PUBLIC_URL}/${solution.thumbImagePath}`}>
                                {solution.icon}
                                {solution.categories.map((item, i) =>
                                    <p key={i}>{item}</p>
                                )}
                            </CaseCard>);
                    })}
                    <CaseCard cardIndex={this.solutions.get().length} onClick={this.props.handleContactFormToggle}>
                        <PlusIcon/>
                        <p>Мы настроим систему под ваш бизнес</p>
                    </CaseCard>
                    <div className={styles.mock}/>
                </div>
                <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
            </div>
        );
    }
}

export default CaseCardsList;