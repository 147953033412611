import React from "react";

function LockIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="33" viewBox="0 0 27 33">
            <path d="M1220.5,3804l12.33,2.74a1.5,1.5,0,0,1,1.17,1.46v14.98a8.99,8.99,0,0,1-4.01,7.49l-9.49,6.33-9.49-6.33a8.99,8.99,0,0,1-4.01-7.49V3808.2a1.5,1.5,0,0,1,1.17-1.46Zm0,3.07-10.5,2.33v13.78a5.984,5.984,0,0,0,2.67,4.99l7.83,5.22,7.83-5.22a5.984,5.984,0,0,0,2.67-4.99V3809.4l-10.5-2.33h0Zm0,5.93a3,3,0,0,1,1.5,5.6v6.4h-3v-6.4A3,3,0,0,1,1220.5,3813Z" transform="translate(-1207 -3804)"/>
        </svg>
    );
}

export default LockIcon;