import React from "react";
import styles from "./ContactsBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import Button from "../../atoms/Button";
import PhoneIcon from "../../atoms/images/PhoneIcon";
import ContactForm from "../ContactForm";
import Dialog from "../../molecules/Dialog";

class ContactsBlock extends React.Component{
    render(){
        return (
            <div className={styles.container} id="contacts">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Позвоните нам</BlockTitle>
                    <BlockParagraph centerDesktop={true}>Закажите звонок, или позвоните нам, <br/> и мы больше расскажем про наши решения</BlockParagraph>
                    <div className={styles.controlsContainer}>
                        <a className={styles.phoneLink} href="tel:+375447708708"><PhoneIcon/>+375 44 7 708 708</a>
                        <Button role="secondary" onClick={this.props.handleContactFormToggle}>Заказать звонок</Button>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default ContactsBlock;