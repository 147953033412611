import React from "react";
import styles from "./AdvantagesBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import SliderIndicators from "../../molecules/SliderIndicators";

class AdvantagesBlock extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scrollEventHandler: null,
            totalAmount: 6
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Преимущества</BlockTitle>
                    <div className={styles.list} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/1.svg" alt="" style={{ marginRight: '-10px' }} />
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        Высокая точность
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        С недоступной другим системам точностью распознает объекты в в сложных условиях и идентифицирует события с минимальной вероятностью ошибки
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/2.svg" alt=""/>
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        Легкая интеграция
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        Легко интегрируется с СКУД, CRM, кассовым ПО и другими IT системами вашей компании
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/3.svg" alt=""/>
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        Гибкая настройка
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        Позволяет создавать сложносоставные сценапии для контроля и точно настраивать модули видеоанализа и отчеты именно под ваши бизнес-процессы и задачи
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/4.svg" alt="" style={{ marginRight: '-7px' }} />
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        100% верификация событий
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        Любые представленные в отчетах данные можно проверить по свзанным видеофрагментам
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/5.svg" alt=""/>
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        Имеющееся оборудование
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        Работает на большинстве оборудования средней ценовой категории (не требуется установка дорогих камер видеонаблюдения)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div data-aos="zoom-in" className={styles.listItemWrap}>
                            <div className={styles.listItem}>
                                <div className={styles.listItemIcon}>
                                    <img src="images/advantages/6.svg" alt=""/>
                                </div>
                                <div className={styles.listItemBody}>
                                    <div className={styles.listItemTitle}>
                                        Гарантированная конфиденциальность
                                    </div>
                                    <div className={styles.listItemDesc}>
                                        Имеет возможность хранить данные как в нашем защищенном облаке так и на ваших серверах
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '-10px' }}>
                        <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default AdvantagesBlock;