import React from "react";
import styles from "./ScrollArrowImage.module.css";

function ScrollArrowImage(props){
  return (
      <svg className={styles.scrollArrowImage} xmlns="http://www.w3.org/2000/svg" width="48" height="77" viewBox="0 0 48 77">
          <path className={styles.arrow}
                d="M958.471,867a0.5,0.5,0,0,1-.5-0.5v-34a0.5,0.5,0,1,1,.994,0v34A0.5,0.5,0,0,1,958.471,867Zm0,0a0.5,0.5,0,0,1-.351-0.146l-6.96-7a0.5,0.5,0,0,1,.7-0.706l6.608,6.646,6.609-6.646a0.5,0.5,0,0,1,.7,0,0.5,0.5,0,0,1,0,.706l-6.96,7A0.5,0.5,0,0,1,958.471,867Z"
                transform="translate(-934.5 -811.5)"/>
          <rect className={styles.container} x="0.5"
                y="0.5" width="47" height="76" rx="20" ry="20"/>
      </svg>


  );
}

export default ScrollArrowImage;