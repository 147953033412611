import React from "react";
import styles from "./WorkflowCard.module.css";

function WorkflowCard(props){
    return (
        <div data-aos="fade-right" data-aos-delay={props.cardIndex * 150} className={styles.workflowCard}>
            <div className={styles.index}>{props.cardIndex ? props.cardIndex : 0}</div>
            {props.children}
        </div>
    );
}

export default WorkflowCard;