import React from "react";
import {HashLink} from "react-router-hash-link";
function ScrollLink(props){
    return <HashLink data-aos={props['data-aos']} data-aos-delay={props['data-aos-delay']} className={props.className} to={props.to} scroll={(el) => {
        const behavior = props.smooth ? 'smooth' : 'instant';
        const offset = 80;
        const top = props.navigateTop ? 0 : el.offsetTop - offset;
        window.scrollTo({ top: top, left: 0, behavior: behavior })
    }}
    onClick={props.onClick}>
        {props.children}
    </HashLink>
}

export default ScrollLink;