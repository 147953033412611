import React from "react";

function BranchesIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="26.812" height="26.81" viewBox="0 0 26.812 26.81">
            <path d="M834.67,3523.72a4.469,4.469,0,1,1-3.136-.06v-9.45a4.473,4.473,0,1,1,2.98,0v4.73a7.384,7.384,0,0,1,4.469-1.49h5.96a4.47,4.47,0,0,0,4.313-3.3,4.462,4.462,0,1,1,3.028.1,7.453,7.453,0,0,1-7.341,6.18h-5.96A4.462,4.462,0,0,0,834.67,3523.72Zm-1.646,2.67a1.49,1.49,0,1,0,1.49,1.49A1.485,1.485,0,0,0,833.024,3526.39Zm0-17.88a1.49,1.49,0,1,0,1.49,1.49A1.485,1.485,0,0,0,833.024,3508.51Zm17.879,0a1.49,1.49,0,1,0,1.49,1.49A1.485,1.485,0,0,0,850.9,3508.51Z" transform="translate(-828.563 -3505.53)"/>
        </svg>
    );
}

export default BranchesIcon;