import React from "react";
import styles from "./SystemConnectionBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle"
import DataProtectionBanner from "../../molecules/DataProtectionBanner";
import SystemConnectionCardsList from "../../molecules/SystemConnectionCardsList";

class SystemConnectionBlock extends React.Component{
    render(){
        return (
            <div className={styles.container} id="scheme">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Подключение системы</BlockTitle>
                    <SystemConnectionCardsList/>
                </CenteredContainer>
            </div>
        );
    }
}

export default SystemConnectionBlock;