import React from "react";

function ParkingIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
            <path d="M446.5,4620h24a1.5,1.5,0,0,1,1.5,1.5v24a1.5,1.5,0,0,1-1.5,1.5h-24a1.5,1.5,0,0,1-1.5-1.5v-24A1.5,1.5,0,0,1,446.5,4620Zm1.5,3v21h21v-21H448Zm6,3h5.25a5.25,5.25,0,0,1,0,10.5H457v4.5h-3v-15Zm3,3v4.5h2.25a2.25,2.25,0,0,0,0-4.5H457Z" transform="translate(-445 -4620)"/>
        </svg>
    );
}

export default ParkingIcon;