import React from "react";
import styles from "./CustomerFeedbackBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";

class CustomerFeedbackBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Мнение заказчика</BlockTitle>
                    <div data-aos="fade-down" className={styles.msg}>
                        <div className={styles.text}>
                            {this.props.customer.feedback.text}
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default CustomerFeedbackBlock;