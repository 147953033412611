import React from "react";

function ClockIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <path d="M844,3836a15,15,0,1,1,15-15A15,15,0,0,1,844,3836Zm0-3a12,12,0,1,0-12-12A12,12,0,0,0,844,3833Zm1.5-12h6v3h-9v-10.5h3v7.5Z" transform="translate(-829 -3806)"/>
        </svg>
    );
}

export default ClockIcon;