import React from "react";
import { Link } from "react-router-dom";
import styles from "./CaseHeader.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";

class CaseHeader extends React.Component{
    render(){
        return (
            <div className={styles.container} style={{ backgroundImage: `url(${this.props.case.introBg})` }}>
                <div className={styles.content}>
                    <CenteredContainer>
                        <div className={styles.wrap}>
                            <div className={styles.column}>
                                <Link data-aos="fade-down" to="/" className={styles.linkToBack}>
                                    <svg className={styles.arrowLeft} width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.9925 3.75L12 3.75L12 2.25L2.9925 2.25L2.9925 -7.87461e-07L2.62268e-07 3L2.9925 6L2.9925 3.75Z" fill="white"/>
                                    </svg>
                                    Назад
                                </Link>
                                <div className={styles.titleBox}>
                                    <BlockTitle style={{ color: "#fff" }}>{this.props.case.title}</BlockTitle>
                                    <BlockParagraph style={{maxWidth: '100%'}} data-aos="fade-down">Кейс по внедрению нейронной сети Cyber Vision Control</BlockParagraph>
                                </div>
                            </div>
                        </div>
                    </CenteredContainer>
                </div>
            </div>
        );
    }
}

export default CaseHeader;