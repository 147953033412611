import React from "react";
import styles from "./InvestmentBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import SecondaryText from "../../atoms/SecondaryText";
import Button from "../../atoms/Button";
import Modal from "../../molecules/Modal";
import Carousel from "../../molecules/Carousel";
import { SwiperSlide } from 'swiper/react';

class InvestmentBlock extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            shownCosultationModal: false,
            shownSampleReport: false,
        };
    }
    toggleConsultationModal = () => {
        this.setState({ shownCosultationModal: !this.state.shownCosultationModal })
    }
    toggleReportModal = () => {
        this.setState({ shownSampleReport: !this.state.shownSampleReport })
    }
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ marginBottom: '70px', textAlign: 'center' }}>CVC - беспроигрышная инвестиция</BlockTitle>
                    <div className={styles.results}>
                        <div data-aos="fade-right" className={styles.item}>
                            <div className={styles.itemTitle}>
                                Ваши затраты
                            </div>
                            <div className={styles.itemList}>
                                <div className={styles.itemListSection}>
                                    <strong>3-4 дня</strong>
                                    <span>на интеграцию CVC</span>
                                </div>
                                <div className={styles.itemListSection}>
                                    <strong>10 минут в день</strong>
                                    <span>на просмотр отчета</span>
                                </div>
                                <div className={styles.itemListSection}>
                                    <strong>от 99 руб в месяц</strong>
                                    <span>на обслуживание системы</span>
                                </div>
                            </div>
                        </div>
                        <div data-aos="fade-left" className={styles.item}>
                            <div className={styles.itemTitle}>
                                Что вы получаете
                            </div>
                            <div className={styles.itemList}>
                                <div className={styles.itemListSection}>
                                    <strong>Отчеты в 3 форматах</strong>
                                </div>
                                <div className={styles.itemListSection}>
                                    <strong>Возможность просматривать<br /> те нарушения, которые отметила<br/> система, и визировать их</strong>
                                </div>
                                <div className={styles.itemListSection}>
                                    <strong>Анализ статистики нарушений<br/> в максимально удобном виде</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.resultsFooter}>
                        <BlockParagraph centerDesktop={true} style={{maxWidth: '730px', margin: '0 auto 24px'}}>
                            Мы готовы бесплатно показать и рассказать, как видеоаналитика может экономить вам 10%+ выручки ежемесячно
                        </BlockParagraph>
                        <div className={styles.buttonSection}>
                            <Button role="primary" onClick={() => this.toggleReportModal()}>Посмотреть примеры отчетов</Button>
                            <Modal isShown={this.state.shownSampleReport} onModalClose={this.toggleReportModal}>
                                <Carousel>
                                    <SwiperSlide>
                                        <img src="/images/screenshots/1.png" alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/images/screenshots/2.png" alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/images/screenshots/3.png" alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/images/screenshots/4.png" alt="" />
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <img src="/images/screenshots/5.png" alt="" />
                                    </SwiperSlide>
                                </Carousel>
                            </Modal>
                        </div>
                        <div className={styles.buttonSection}>
                            <button onClick={() => this.toggleConsultationModal()} className={styles.resultsFooterLink}>Как проходит консультация?</button>
                            <Modal isShown={this.state.shownCosultationModal} onModalClose={this.toggleConsultationModal}>
                                <ol>
                                    <li>Вы описываете ваш бизнес и выбираете способ связи.</li>
                                    <li>Вместе определяем проблемы, запросы и потенциальные решения.</li>
                                    <li>Проводим осмотр объекта на предмет географии пространства и текущего оборудования (можно ли его использовать, чтобы минимизировать расходы).</li>
                                    <li>Предлагаем конкретные решения (рекомендуемые модули, как они будут работать).</li>
                                </ol>
                                <div style={{ textAlign: 'center' }}>
                                    <Button role="primary" onClick={() => { this.props.handleContactFormToggle() }}>Получить консультацию</Button>
                                </div>
                            </Modal>
                        </div>
                        <SecondaryText className={styles.secondaryText}>
                            *Консультация ни к чему вас не обязывает
                        </SecondaryText>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default InvestmentBlock;