import React from "react";
import styles from "./CapabilityCard.module.css";

function CapabilityCard(props){
    const types = ['accent'];
    const additionalClassName = props.type && types.includes(props.type) ? props.type : false;
    return (
        <div data-aos="zoom-in" className={`${styles.capabilityCard} ${additionalClassName ? styles[additionalClassName] : ''}`}>
            {props.children}
        </div>
    );
}

export default CapabilityCard;