import React from "react";

function PhoneIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
            <path d="M456.549,3515.52a15.871,15.871,0,0,0,5.928,5.93l1.326-1.86a1.491,1.491,0,0,1,1.941-.44,16.965,16.965,0,0,0,6.874,2.04,1.512,1.512,0,0,1,1.382,1.5v6.69a1.508,1.508,0,0,1-1.347,1.5,23.985,23.985,0,0,1-2.4.12A23.251,23.251,0,0,1,447,3507.75a22.956,22.956,0,0,1,.123-2.4,1.5,1.5,0,0,1,1.492-1.35h6.693a1.5,1.5,0,0,1,1.5,1.38,17.092,17.092,0,0,0,2.046,6.87,1.512,1.512,0,0,1-.444,1.95Zm-3.783-.98,2.85-2.04a20.19,20.19,0,0,1-1.646-5.5h-3.955q-0.014.375-.014,0.75A20.251,20.251,0,0,0,470.25,3528c0.25,0,.5-0.01.75-0.02v-3.95a19.99,19.99,0,0,1-5.5-1.65l-2.036,2.85a19,19,0,0,1-2.382-1.12l-0.087-.05a18.841,18.841,0,0,1-7.053-7.06l-0.049-.08A18.723,18.723,0,0,1,452.766,3514.54Z" transform="translate(-447 -3504)"/>
        </svg>
    );
}

export default PhoneIcon;