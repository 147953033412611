import React from "react";
import styles from "./Modal.module.css";
import CloseButton from "../../atoms/CloseButton/CloseButton";

function Modal(props){
    let additionalClassName = props.isShown ? 'shown' : 'hidden';
    return (
        <div className={`modal ${styles.modalWrapper} ${additionalClassName ? styles[additionalClassName] : ''}`}>
            <div className={styles.modalBody}>
                <div className={styles.closeButtonContainer}><CloseButton onClick={props.onModalClose}/></div>
                <div className={styles.modalContent}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Modal;