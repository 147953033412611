import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import CaseHeader from "../../organisms/CaseHeader";
import CustomerBlock from "../../organisms/CustomerBlock";
import TaskBlock from "../../organisms/TaskBlock";
import IntegrationBlock from "../../organisms/IntegrationBlock";
import ResultBlock from "../../organisms/ResultBlock";
// import PaybackBlock from "../../organisms/PaybackBlock";
import CustomerFeedbackBlock from "../../organisms/CustomerFeedbackBlock";
import AdditionalInfoBlock from "../../organisms/AdditionalInfoBlock";
import ConclusionBlock from "../../organisms/ConclusionBlock";
import ContactsBlock from "../../organisms/ContactsBlock";
import Footer from "../../organisms/Footer";
import HistorySuccessBlock from "../../organisms/HistorySuccessBlock";

class CasePageTemplate extends React.Component{
    render(){
        return (
            <div>
                <NavigationBar/>
                <CaseHeader case={this.props.case} />
                <CustomerBlock customer={this.props.case.customer} />
                <TaskBlock case={this.props.case} />
                <IntegrationBlock case={this.props.case} />
                <ResultBlock results={this.props.case.results} />
                {/* <PaybackBlock /> */}
                { this.props.case.customer.feedback && <CustomerFeedbackBlock customer={this.props.case.customer} /> }
                { this.props.case.additional && <AdditionalInfoBlock additional={this.props.case.additional} /> }
                <ConclusionBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <HistorySuccessBlock backgroundColor="#fff" alias={this.props.case.alias} />
                <ContactsBlock handleContactFormToggle={this.props.handleContactFormToggle} />
                <Footer/>
            </div>
        );
    }
}

export default CasePageTemplate;