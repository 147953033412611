import React from "react";

function CafeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30">
            <path d="M472,3997v30h-3v-10.5h-6V4006A9,9,0,0,1,472,3997Zm-3,3.79a6.037,6.037,0,0,0-3,5.21v7.5h3v-12.71Zm-15,14.06V4027h-3v-12.15a7.5,7.5,0,0,1-6-7.35v-9h3V4009h3v-10.5h3V4009h3v-10.5h3v9A7.5,7.5,0,0,1,454,4014.85Z" transform="translate(-445 -3997)"/>
        </svg>
    );
}

export default CafeIcon;