import React from "react";

function RecognitionSystem(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="56" viewBox="0 0 42 56">
            <path
                  d="M1034.75,1264.22a1.79,1.79,0,1,1-1.75,1.79A1.767,1.767,0,0,1,1034.75,1264.22Zm10.5,0a1.79,1.79,0,1,1-1.75,1.79A1.767,1.767,0,0,1,1045.25,1264.22Zm-0.58-19.06a7.066,7.066,0,0,1,6.9,5.96h-3.4a1.158,1.158,0,0,0-.6.17l-3.84,2.35a7.15,7.15,0,0,1-7.46,0l-3.24-1.99a7.074,7.074,0,0,1,6.97-6.49h4.67Zm-12.84,1.19h0.89a9.635,9.635,0,0,0-1.98,4.77h-2.41v-1.19A3.554,3.554,0,0,1,1031.83,1246.35Zm-3.5,23.83V1253.5h3.18l3.56,2.18a9.409,9.409,0,0,0,9.86,0l3.56-2.18h3.18v16.68A11.672,11.672,0,1,1,1028.33,1270.18Zm11.67,14.3a13.7,13.7,0,0,0,5.83-1.3v2.49a5.831,5.831,0,1,1-11.66,0v-2.49A13.7,13.7,0,0,0,1040,1284.48Zm14,3.62-5.83-3.15v-3.16a14.4,14.4,0,0,0,5.83-11.61v-17.87a9.442,9.442,0,0,0-9.33-9.53H1040a9.157,9.157,0,0,0-4.51,1.19h-3.66a5.9,5.9,0,0,0-5.83,5.96v20.25a14.4,14.4,0,0,0,5.83,11.61v3.16l-6.1,3.3-0.01,2.7,6.32-3.42a8.17,8.17,0,0,0,9.79,6.27,8.262,8.262,0,0,0,6.13-6.27l6.27,3.39Zm-32.67-47.7H1026v-2.39h-5.83a1.182,1.182,0,0,0-1.17,1.19h0v5.96h2.33v-4.76Zm39.67,4.76v-5.96a1.182,1.182,0,0,0-1.17-1.19H1054v2.39h4.67v4.76H1061Zm-42,38.13a1.182,1.182,0,0,0,1.17,1.19H1026v-2.39h-4.67v-4.76H1019v5.96Zm35,1.19h5.83a1.182,1.182,0,0,0,1.17-1.19h0v-5.96h-2.33v4.76H1054v2.39Zm-23.33-25.02h5.83v2.38h-5.83v-2.38Zm12.83,0h5.83v2.38h-5.83v-2.38Z"
                  transform="translate(-1019 -1238)"/>
        </svg>
    );
}

export default RecognitionSystem;