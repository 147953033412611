import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import Footer from "../../organisms/Footer";
import styles from "./NotFoundPageTemplate.module.css"
import BlockParagraph from "../../atoms/BlockParagraph";
import CenteredContainer from "../../molecules/CenteredContainer";
import Button from "../../atoms/Button";
import { Link } from "react-router-dom";
import BlockTitle from "../../atoms/BlockTitle";
import Dialog from "../../molecules/Dialog";
import ContactForm from "../../organisms/ContactForm";

class NotFoundPageTemplate extends React.Component{
    render(){
        return (
            <div>
                <NavigationBar/>
                <CenteredContainer>

                    <div className={styles.content}>
                        <h1 data-aos="zoom-in-up"  className={styles.title}>
                            <span className={styles.statusCode}>404</span>
                            <span className={styles.statusText}>Not found.</span>
                        </h1>
                        <BlockTitle>Что это значит?</BlockTitle>
                        <BlockParagraph>
                            Мы не смогли найти запрашиваемую страницу.
                            <br/>Возможно, при вводе адреса была допущена опечатка. Ресурс мог также быть удалён с сервера.
                            <br/>Если Вы считаете, что ситуация возникла из-за технической ошибки, свяжитесь с нами.
                        </BlockParagraph>
                        <div className={styles.buttons}>
                            <Link to="/">
                                <Button role="primary">Перейти на главную</Button>
                            </Link>
                            <Button role="secondary" onClick={this.props.handleContactFormToggle}>Связаться с нами</Button>
                        </div>
                    </div>
                    <Footer/>
                </CenteredContainer>
            </div>
        );
    }
}

export default NotFoundPageTemplate;