import React from "react";
import styles from "./FullReportingCard.module.css";

function FullReportingCard(props){
    const types = ['white', 'orange', 'blue'];
    const additionalClassName = props.type && types.includes(props.type) ? props.type : false;
    return (
        <div data-aos="zoom-in" data-aos-delay={(props.cardIndex - 1) * 150} className={`${styles.fullReportingCard} ${additionalClassName ? styles[additionalClassName] : ''}`}>
            {props.children}
        </div>
    );
}

export default FullReportingCard;