import React from "react";
import styles from "./MenuToggle.module.css";
function MenuToggle(props){
    return <div className={`${styles.menuToggle} ${props.isExpanded ? styles.expanded : styles.collapsed}`}>
        <div className={styles.bar}> </div>
        <div className={styles.bar}> </div>
        <div className={styles.bar}> </div>
    </div>;
}

export default MenuToggle;