import React from "react";

function OfficeIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33" height="29.87" viewBox="0 0 33 29.87">
            <path d="M1208,4023.83v-20.09a1.492,1.492,0,0,1,.99-1.42l14.5-5.31a0.741,0.741,0,0,1,.96.45,0.751,0.751,0,0,1,.05.26v7.48l9.47,3.18a1.5,1.5,0,0,1,1.03,1.43v14.02h3v3.02h-33v-3.02h3Zm3,0h10.5v-22.88l-10.5,3.85v19.03Zm21,0V4010.9l-7.5-2.52v15.45h7.5Z" transform="translate(-1205 -3996.97)"/>
        </svg>
    );
}

export default OfficeIcon;