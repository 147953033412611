import React from "react";
import styles from "./CaseCard.module.css";
import ScrollLink from "../../atoms/ScrollLink";

function CaseCard(props){
    const isLink = !!props.to;
    if (isLink){
        return  <ScrollLink data-aos="zoom-in" data-aos-delay={(props.cardIndex - 1) * 50} to={props.to} className={styles.caseCard} navigateTop>
                    {props.children}
                     <div className={styles.bgImageContainer} style={{background: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}/>
                </ScrollLink>;
    }else{
        return  <div  data-aos="zoom-in" data-aos-delay={(props.cardIndex - 1) * 50} className={`${styles.caseCard} ${styles.caseCardEmpty}`} onClick={props.onClick}>
                    {props.children}
                    <div className={styles.bgImageContainer} style={{background: props.backgroundImage ? `url(${props.backgroundImage})` : ''}}/>
                </div>;
    }
}

export default CaseCard;