import React from "react";
import styles from "./SolutionHeaderBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import SolutionHeaderForm from "../../molecules/SolutionHeaderForm";
import ScrollArrowImage from "../../atoms/images/ScrollArrowImage";
import ScrollLink from "../../atoms/ScrollLink/ScrollLink";
import InfrastructureImage from "../../atoms/images/InfrastructureImage";

class SolutionHeaderBlock extends React.Component{
    render(){
        return (
            <CenteredContainer>
                <div className={styles.introductionBlockTopContent}>
                    <InfrastructureImage />
                    <SolutionHeaderForm keys={this.props.solution.keys} />
                </div>
                <div className={styles.introductionBlockBottomContent}>
                    <ScrollLink to="#theft" smooth>
                        <ScrollArrowImage/>
                    </ScrollLink>
                </div>
            </CenteredContainer>
        );
    }
}

export default SolutionHeaderBlock;