import React from "react";
import styles from "./WorkflowCardsList.module.css";
import WorkflowCard from "../WorkflowCard";

function WorkflowCardsList(props){
    return (
        <div className={styles.cardsContainer}>
            <WorkflowCard cardIndex={1}>
                <h3>Подготовка списка <br/> требований</h3>
                <p>В первую очередь обсуждаем проблематику и особенности бизнеса, совместно с Вами составляем список требований</p>
            </WorkflowCard>
            <WorkflowCard cardIndex={2}>
                <h3>Проверка <br/>
                    возможностей</h3>
                <p>Анализируем видео с Ваших камер наблюдения, формируем список возможностей</p>
            </WorkflowCard>
            <WorkflowCard cardIndex={3}>
                <h3>Интеграция <br/>
                    системы</h3>
                <p>После демонстрации и анализа, понимая возможности каждой камеры, приступаем к интеграции и запуску системы</p>
            </WorkflowCard>
        </div>
    );
}

export default WorkflowCardsList;