import React from "react";
import styles from "./FullReportingBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle"
import FullReportingCardsList from "../../molecules/FullReportingCardsList";

class FullReportingBlock extends React.Component{
    render(){
        return (
            <div className={styles.container} id="scheme">
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Мы предоставляем полную <br/> отчетность по работе системы</BlockTitle>
                    <FullReportingCardsList/>
                </CenteredContainer>
            </div>
        );
    }
}

export default FullReportingBlock;