import React from "react";
import styles from "./IntroductionForm.module.css";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import Button from "../../atoms/Button";
import ScrollLink from "../../atoms/ScrollLink";

import Modal from "../../molecules/Modal";
import YouTube from "../../molecules/YouTube";

class IntroductionForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shownVideomModal: false
        };
    }
    toggleVideoModal = () => {
        this.setState({ shownVideomModal: !this.state.shownVideomModal })
    }
    render() {
        return (
            <div className={styles.introductionForm}>
                <BlockTitle type='large' style={{ marginTop: 0 }}>
                    Контроль бизнес-процессов с помощью нейросетевой видеоаналитики
                </BlockTitle>
                <BlockParagraph>Сокращение ваших потерь от мошенничества, нарушений регламентов и дисциплины. Роботизированный сбор маркетинговых данных</BlockParagraph>
                <div className={styles.blockButtons}>
                    <ScrollLink to="/#scheme" smooth>
                        <Button role="primary">Как это работает</Button>
                    </ScrollLink>
                    <Button role="secondary" onClick={() => this.toggleVideoModal()}>
                        Видео
                    </Button>
                    <ScrollLink to="/#history" smooth>
                        <Button role="secondary" >Кейсы</Button>
                    </ScrollLink>
                    <Modal isShown={this.state.shownVideomModal} onModalClose={this.toggleVideoModal}>
                        { this.state.shownVideomModal && <YouTube videoId={'inWXSQtxOpI'} /> }
                    </Modal>
                </div>
            </div>
        );
    }
}

export default IntroductionForm;