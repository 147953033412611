import React from "react";
import styles from "./IntegrationBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";

class IntegrationBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Интеграция CVC</BlockTitle>
                    <div className={styles.timeLine}>
                        {this.props.case.integration.map((item, i) =>
                            <div data-aos={i % 2 === 1 ? 'fade-right' : 'fade-left'} data-aos-delay={i * 100} className={styles.timeLineStep} key={i}>
                                <div className={styles.timeLineStepItem}>
                                    <div className={styles.timeLineStepItemNum}>{i + 1}</div>
                                    <div className={styles.timeLineStepItemDesc}>
                                        {item}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default IntegrationBlock;