import solutions from '../static/json/soluitons';
import * as React from "react";

let instance = null;

class Solutions {
    constructor() {
        if(!instance){
            instance = this;
        }
        this.solutionsArray = solutions ? solutions : [];
        this.solutionsArray = this.loadIcons(this.solutionsArray);
        return instance;
    }

    get(){
        return [...this.solutionsArray];
    }

    getByAlias(alias){
        alias = alias ? alias.trim() : false;
        const foundSolution = this.solutionsArray.find(s => s.alias === alias);
        return foundSolution ? foundSolution : false;
    }

    loadIcons(solutionsArray){
        return solutionsArray.map(solution => {
            if (solution.iconName){
                const importedIcon = require("../components/atoms/images/CaseIcons/" + solution.iconName);
                solution.icon = React.createElement(importedIcon.default);
            }
            return solution;
        });
    };
}

export default Solutions;