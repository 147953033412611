import React from "react";

function GearIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28.97" viewBox="0 0 30 28.97">
            <path d="M447,3821.49a15.2,15.2,0,0,1,.474-3.77,4.512,4.512,0,0,0,4.011-6.96,14.957,14.957,0,0,1,6.5-3.77,4.5,4.5,0,0,0,6.051,1.98,4.553,4.553,0,0,0,1.971-1.98,14.945,14.945,0,0,1,6.5,3.77,4.507,4.507,0,0,0,4.013,6.96,15.19,15.19,0,0,1,0,7.53,4.519,4.519,0,0,0-4.013,6.97,15.024,15.024,0,0,1-6.5,3.76,4.5,4.5,0,0,0-8.022,0,15.035,15.035,0,0,1-6.5-3.76,4.51,4.51,0,0,0-4.013-6.97A15.154,15.154,0,0,1,447,3821.49Zm7.206,4.51a7.531,7.531,0,0,1,.846,5.3,12.132,12.132,0,0,0,1.946,1.13,7.477,7.477,0,0,1,10,0,12.132,12.132,0,0,0,1.946-1.13,7.533,7.533,0,0,1,5-8.69,12.08,12.08,0,0,0,0-2.25,7.532,7.532,0,0,1-5-8.69,12.122,12.122,0,0,0-1.946-1.12,7.5,7.5,0,0,1-10,0,12.122,12.122,0,0,0-1.946,1.12,7.533,7.533,0,0,1-5,8.69,12.254,12.254,0,0,0,0,2.25,7.537,7.537,0,0,1,4.157,3.39h0Zm7.795,0a4.515,4.515,0,1,1,4.5-4.51A4.506,4.506,0,0,1,462,3826Zm0-3.01a1.505,1.505,0,1,0-1.5-1.5A1.5,1.5,0,0,0,462,3822.99Z" transform="translate(-447 -3807)"/>
        </svg>
    );
}

export default GearIcon;