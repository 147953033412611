import React from "react";
import styles from "./SolutionPriceBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import SecondaryText from "../../atoms/SecondaryText";
import Tariffs from "../../molecules/Tariffs";

class SolutionPriceBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Сколько это стоит?</BlockTitle>
                    <div className={styles.categoryList}>
                        { this.props.solution.categories.map((item, i) =>
                            <button className={styles.categoryListItem} key={i}>{item}</button>
                        )}
                    </div>
                    <Tariffs tariff={{ category: this.props.solution.alias }} handleContactFormToggle={this.props.handleContactFormToggle} />
                    <SecondaryText center style={{ marginTop: '30px' }}>*сервер на стороне заказчика, одна камера, без учета интеграции</SecondaryText>
                </CenteredContainer>
            </div>
        );
    }
}

export default SolutionPriceBlock;