import React from "react";

function InfrastructureIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
            <path d="M1210.42,4921.06h3.61v1.81h-3.61v-1.81Zm47.87-3.61a2.712,2.712,0,0,0,2.71-2.71v-9.03a2.719,2.719,0,0,0-2.71-2.71h-12.65a2.71,2.71,0,0,0-2.7,2.71v9.03a2.7,2.7,0,0,0,2.7,2.71h2.71v1.81h-1.8v1.8h4.51v9.04h-3.72a6.194,6.194,0,0,0-2.6-1.54v-0.27a7.217,7.217,0,0,0-13.31-3.88,7.152,7.152,0,0,0-2.95-.64,7.276,7.276,0,0,0-6.75,4.66,6.637,6.637,0,0,0-1.91.76h-6.69v-2.71h3.61a2.706,2.706,0,0,0,2.71-2.71v-18.06a2.712,2.712,0,0,0-2.71-2.71h-9.03a2.719,2.719,0,0,0-2.71,2.71v18.06a2.712,2.712,0,0,0,2.71,2.71h3.61V4931h6.58a6.182,6.182,0,0,0-.87,1.81h-3.9v9.93h-3.61a2.712,2.712,0,0,0-2.71,2.71v8.13H1205v2.71a2.719,2.719,0,0,0,2.71,2.71h14.45a2.719,2.719,0,0,0,2.71-2.71v-2.71h-1.81v-8.13a2.712,2.712,0,0,0-2.71-2.71h-5.41v-8.13h1.8a6.336,6.336,0,0,0,6.32,6.33h19.88a6.341,6.341,0,0,0,6.25-5.42h3.68v7.22h-6.32V4959H1261v-16.26h-6.32v-9.03h-5.5a6.247,6.247,0,0,0-.56-1.81h4.25v-10.84h4.52v-1.8h-1.81v-1.81h2.71Zm-40.64,0h-10.84v-9.03h10.84v9.03Zm-9.94-12.64h9.03a0.9,0.9,0,0,1,.91.9v0.9h-10.84v-0.9A0.9,0.9,0,0,1,1207.71,4904.81Zm-0.9,18.96v-4.51h10.84v4.51a0.913,0.913,0,0,1-.91.91h-9.03A0.9,0.9,0,0,1,1206.81,4923.77Zm16.25,32.52a0.9,0.9,0,0,1-.9.9h-14.45a0.9,0.9,0,0,1-.9-0.9v-0.9h16.25v0.9Zm-1.8-10.84v8.13h-12.65v-8.13a0.9,0.9,0,0,1,.91-0.9h10.83A0.9,0.9,0,0,1,1221.26,4945.45Zm27.09,11.74v-5.42h10.84v5.42h-10.84Zm10.84-7.22h-10.84v-5.42h10.84v5.42Zm-16.25-10.84h-19.88a4.517,4.517,0,0,1-.54-9l0.6-.07,0.16-.57a5.4,5.4,0,0,1,8.04-3.1l0.86,0.53,0.43-.92a5.418,5.418,0,0,1,10.33,2.29,5.6,5.6,0,0,1-.08.84l-0.13.89,0.89,0.13A4.516,4.516,0,0,1,1242.94,4939.13Zm2.7-34.32h12.65a0.9,0.9,0,0,1,.9.9v6.32h-14.45v-6.32A0.9,0.9,0,0,1,1245.64,4904.81Zm-0.9,9.93v-0.9h14.45v0.9a0.9,0.9,0,0,1-.9.9h-12.65A0.9,0.9,0,0,1,1244.74,4914.74Zm9.03,4.52h-3.61v-1.81h3.61v1.81Zm-3.61,27.09h1.81v1.81h-1.81v-1.81Zm5.42,7.23h1.81v1.81h-1.81v-1.81Zm-1.81-7.23h3.62v1.81h-3.62v-1.81Zm-3.61,7.23h3.61v1.81h-3.61v-1.81Z" transform="translate(-1205 -4903)"/>
        </svg>
    );
}

export default InfrastructureIcon;