import React from "react";

function TwitterLogo(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="15" viewBox="0 0 19 15">
            <path fill="black"
                  d="M1445,94.775a8.381,8.381,0,0,1-2.24.6,3.81,3.81,0,0,0,1.71-2.092,7.949,7.949,0,0,1-2.47.916,3.962,3.962,0,0,0-2.85-1.2,3.842,3.842,0,0,0-3.89,3.786,4.041,4.041,0,0,0,.09.863,11.147,11.147,0,0,1-8.03-3.959,3.722,3.722,0,0,0,1.2,5.059,3.923,3.923,0,0,1-1.76-.466v0.041a3.83,3.83,0,0,0,3.12,3.719,3.96,3.96,0,0,1-1.02.125,3.569,3.569,0,0,1-.74-0.065,3.929,3.929,0,0,0,3.64,2.637,7.962,7.962,0,0,1-4.83,1.614,7.519,7.519,0,0,1-.93-0.052,11.216,11.216,0,0,0,5.98,1.7,10.846,10.846,0,0,0,11.08-10.77c0-.167,0-0.329-0.01-0.489A7.7,7.7,0,0,0,1445,94.775Z"
                  transform="translate(-1426 -93)"/>
        </svg>
    );
}

export default TwitterLogo;