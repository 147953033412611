import React from "react";
import styles from "./SliderIndicators.module.css";

class SliderIndicators extends React.Component{
    constructor(props){
        super(props);
        props.assignScrollEventHandler(this.handleScrollEvent.bind(this));
        this.state = {
            currentIndex: props.currentIndex,
            totalAmount: props.totalAmount
        };
    }

    handleScrollEvent(event){
        const slides = [...event.target.children];
        for (let i = 0; i < this.state.totalAmount; i++){
            const el = slides[i];
            const rect = el.getBoundingClientRect();
            const isInView = rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
            if (isInView){
                this.setCurrentIndex(i);
                break;
            }
        }
    }

    setCurrentIndex(index){
        this.setState({
            currentIndex: index,
            totalAmount: this.state.totalAmount
        })
    }

    render(){
        const indicators = [];
        for (let i = 0; i < this.state.totalAmount; i++){
            indicators.push(<div key={i} className={`${styles.circle} ${this.state.currentIndex === i ? styles.active : ''}`}/>)
        }

        return (<div className={styles.sliderIndicators}>
                    {indicators}
                </div>);
    }
}

export default SliderIndicators;