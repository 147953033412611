import React from "react";
import styles from "./ConclusionBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import Button from "../../atoms/Button";

class ConclusionBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>CVC сокращает потери в любом бизнесе.<br /> Малом и крупном, на производственном предприятии и в сфере услуг.</BlockTitle>
                    <div data-aos="fade-down" className={styles.buttons}>
                        {/* <Button role="secondary" onClick={this.props.handleContactFormToggle}>Запроить демонстрацию</Button> */}
                        <Button role="primary" onClick={this.props.handleContactFormToggle}>Бесплатная консультация</Button>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default ConclusionBlock;