import React from "react";

function MarketIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" viewBox="0 0 27 30">
            <path d="M830.25,3997h16.5a1.493,1.493,0,0,1,1.2.6L852,4003v22.5a1.5,1.5,0,0,1-1.5,1.5h-24a1.5,1.5,0,0,1-1.5-1.5V4003l4.05-5.4A1.493,1.493,0,0,1,830.25,3997Zm18.75,9H828v18h21v-18Zm-0.75-3-2.25-3H831l-2.25,3h19.5ZM834,4009v3a4.5,4.5,0,0,0,9,0v-3h3v3a7.5,7.5,0,0,1-15,0v-3h3Z" transform="translate(-825 -3997)"/>
        </svg>
    );
}

export default MarketIcon;