import React from "react";
import styles from "./CapabilitiesCategory.module.css";
import SolutionCapabilitiesCard from "../SolutionCapabilitiesCard";
import SliderIndicators from "../SliderIndicators";

class CapabilitiesCategory extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scrollEventHandler: null,
            totalAmount: this.props.category.items.length
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }

    render(){
        return (
            <div className={styles.container}>
                <h3 className={styles.title}>{this.props.category.title}</h3>
                <div className={styles.outerWrapper}>
                    <div className={styles.cardsContainer} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                        {this.props.category.items.map((capability, key) => {
                            return <SolutionCapabilitiesCard key={key} cardIndex={key}>
                                <img src={capability.icon} alt=""/>
                                <h3>{capability.title}</h3>
                                {(capability.description && capability.description.length) && <p>{capability.description}</p>}
                            </SolutionCapabilitiesCard>;
                        })}
                        <div className={styles.mock}/>
                    </div>
                    <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
                </div>
            </div>
        );
    }
}

export default CapabilitiesCategory;