import React from "react";
import styles from "./CasesBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";
import CaseCardsList from "../../molecules/CaseCardsList";

class CasesBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle centerDesktop={true}>Сферы применения</BlockTitle>
                    <BlockParagraph centerDesktop={true} style={{maxWidth: '100%'}}>Система показала высокую эффективность во многих сферах бизнеса. <br/> Кроме стандартных решений, возможна индивидуальная настройка под клиента</BlockParagraph>
                    <CaseCardsList handleContactFormToggle={this.props.handleContactFormToggle}/>
                </CenteredContainer>
            </div>
        );
    }
}

export default CasesBlock;