import React from "react";
import styles from "./NavigationListItem.module.css";
import ScrollLink from "../ScrollLink";

function NavigationListItem(props){
    return (
        <ScrollLink to={props.to} smooth>
            <div className={styles.navigationListItem} onClick={props.onClick}>{props.children}</div>
        </ScrollLink>
    );
}

export default NavigationListItem;