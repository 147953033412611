import React from "react";
import styles from "./SystemConnectionCardsList.module.css";
import SystemConnectionCard from "../SystemConnectionCard";
import PhoneIcon from "../../atoms/images/SystemConnectionIcons/PhoneIcon";
import BranchesIcon from "../../atoms/images/SystemConnectionIcons/BranchesIcon";
import PlusIcon from "../../atoms/images/SystemConnectionIcons/PlusIcon";
import GearIcon from "../../atoms/images/SystemConnectionIcons/GearIcon";
import ClockIcon from "../../atoms/images/SystemConnectionIcons/ClockIcon";
import LockIcon from "../../atoms/images/SystemConnectionIcons/LockIcon";
import SliderIndicators from "../SliderIndicators";

class SystemConnectionCardsList extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            scrollEventHandler: null,
            totalAmount: 6
        };
    }

    assignScrollEventHandler(eventHandler){
        this.setState({
            scrollEventHandler: eventHandler,
            totalAmount: this.state.totalAmount
        });
    }

    render() {
        return (
            <div className={styles.outerWrapper}>
                <div className={styles.container} onScroll={(event) => {this.state.scrollEventHandler(event)}}>
                    <SystemConnectionCard cardIndex={1}>
                        <PhoneIcon/>
                        <h3>Бесплатная консультация</h3>
                        <p>Проконсультируем вас в короткие сроки и подскажем как лучше решить ваши бизнес-задачи</p>
                    </SystemConnectionCard>
                    <SystemConnectionCard cardIndex={2}>
                        <BranchesIcon/>
                        <h3>Простая интеграция</h3>
                        <p>Подключение к локальной системе видеонаблюдения (любое количество камер)</p>
                    </SystemConnectionCard>
                    <SystemConnectionCard cardIndex={3}>
                        <PlusIcon/>
                        <h3>Масштабируемость системы</h3>
                        <p>Неограниченное подключение дополнитеельных объектов (актульно для сетевого бизнеса)</p>
                    </SystemConnectionCard>
                    <SystemConnectionCard cardIndex={4}>
                        <GearIcon/>
                        <h3>Тренировка алгоритмов</h3>
                        <p>Система полностью адаптируется и настраивается под ваши бизнес-процессы и потребности</p>
                    </SystemConnectionCard>
                    <SystemConnectionCard cardIndex={5}>
                        <ClockIcon/>
                        <h3>Быстрое подключение системы</h3>
                        <p>Подключение и настройка системы от нескольких дней</p>
                    </SystemConnectionCard>
                    <SystemConnectionCard cardIndex={6}>
                        <LockIcon/>
                        <h3>Надежная защита персональных данных</h3>
                        <p>Результаты анализа хранятся в зашифрованном виде, доступ предоставляется только заказчику</p>
                    </SystemConnectionCard>
                    <div className={styles.mock} />
                </div>
                <SliderIndicators assignScrollEventHandler={this.assignScrollEventHandler.bind(this)} totalAmount={this.state.totalAmount} currentIndex={0}/>
            </div>
        );
    }
}

export default SystemConnectionCardsList;