import React, { useRef, useCallback } from "react";
import styles from "./Carousel.module.css";

import { Swiper } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import './override.css';

SwiperCore.use([Navigation, Pagination]);

function Carousel(props) {
    const swiperRef = useRef(null);

    const prevSlide = useCallback(() => {
        swiperRef.current.swiper.slidePrev();
    }, [swiperRef]);

    const nextSlide = useCallback(() => {
        swiperRef.current.swiper.slideNext();
    }, [swiperRef]);

    const slidesPerView = props.slidesPerView || 1

    const defaultBrk = {
        320: { slidesPerView: 1 },
        480: { slidesPerView: 1 },
        640: { slidesPerView: 2 },
        998: { slidesPerView: 3 }
    }
    
    const brk = slidesPerView === 1 ? {} : defaultBrk

    return (
        <React.Fragment>
            <div className="swiper-button-next" onClick={prevSlide} />
            <Swiper
                ref={swiperRef}
                // navigation
                pagination={{ clickable: true }}
                slidesPerView={1}
                spaceBetween={30}
                className={styles.carousel}
                breakpoints={brk}
                style={props.style}
                loop={true}
                // isDuplicate={true}
            >
                { props.children }
            </Swiper>
            <div className="swiper-button-prev" onClick={nextSlide} />
        </React.Fragment>
    );
}

export default Carousel;