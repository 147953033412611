import React from "react";

function GasStationIcon(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="27" viewBox="0 0 30 27">
            <path d="M1223,4334h1.5v3H1205v-3h1.5v-22.5a1.5,1.5,0,0,1,1.5-1.5h13.5a1.5,1.5,0,0,1,1.5,1.5v12h3a3,3,0,0,1,3,3v6a1.5,1.5,0,0,0,3,0V4322h-3a1.5,1.5,0,0,1-1.5-1.5v-5.38l-2.49-2.49,2.13-2.12,7.42,7.43a1.5,1.5,0,0,1,.44,1.06v13.5a4.5,4.5,0,0,1-9,0v-6h-3v7.5Zm-13.5,0H1220v-9h-10.5v9Zm0-21v9H1220v-9h-10.5Z" transform="translate(-1205 -4310)"/>
        </svg>
    );
}

export default GasStationIcon;