import React from "react";
import styles from "./ClientsBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";

class ClientsBlock extends React.Component{
    render(){
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Нам доверяют</BlockTitle>
                    <div className={styles.list}>
                        <div data-aos="flip-up" data-aos-delay="0" className={styles.listItem}>
                            <img src="/images/clients/phali-hinkali.png" alt="Пхали Хинкали" />
                        </div>
                        <div data-aos="flip-up" data-aos-delay="100" className={styles.listItem}>
                            <img src="/images/clients/expobel.png" alt="Expobel" />
                        </div>
                        <div data-aos="flip-up" data-aos-delay="200" className={styles.listItem}>
                            <img src="/images/clients/tokyo-city.png" alt="Tokyo City" />
                        </div>
                        <div data-aos="flip-up" data-aos-delay="300" className={styles.listItem}>
                            <img src="/images/clients/piterland.png" alt="Piterland" />
                        </div>
                        <div data-aos="flip-up" data-aos-delay="400" className={styles.listItem}>
                            <img src="/images/clients/vkusvill.png" alt="Вкусвилл" />
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default ClientsBlock;