import React from "react";
import styles from "./PrincipleOperationBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";

class PrincipleOperationBlock extends React.Component{
    render(){
        return (
            <div id="scheme" className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Принцип работы системы CVC</BlockTitle>
                    <div data-aos="zoom-in" className={styles.illustration}>
                        <img src="/images/principleOperation.svg" alt=""/>
                    </div>
                    <div className={styles.list}>
                        <div data-aos="flip-left" data-aos-delay="0" className={styles.listItem}>
                            <div className={styles.listItemNum}>
                                1
                            </div>
                            <div className={styles.listItemName}>
                                Работает в режиме онлайн 24/7
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-delay="500" className={styles.listItem}>
                            <div className={styles.listItemNum}>
                                2
                            </div>
                            <div className={styles.listItemName}>
                                Определяет нарушения с точностью до 99,7%
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-delay="1000" className={styles.listItem}>
                            <div className={styles.listItemNum}>
                                3
                            </div>
                            <div className={styles.listItemName}>
                                Сигнализирует о критических событиях уведомленим на телефон
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-delay="1500" className={styles.listItem}>
                            <div className={styles.listItemNum}>
                                4
                            </div>
                            <div className={styles.listItemName}>
                                Требует всего 10 минут в день на анализ отчета
                            </div>
                        </div>
                        <div data-aos="flip-left" data-aos-delay="2000" className={styles.listItem}>
                            <div className={styles.listItemNum}>
                                5
                            </div>
                            <div className={styles.listItemName}>
                                Отслеживает одновременно большое количество сценариев
                            </div>
                        </div>
                    </div>
                </CenteredContainer>
            </div>
        );
    }
}

export default PrincipleOperationBlock;