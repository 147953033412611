import React from "react";
import Select from 'react-select';
import styles from "./PriceBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import SecondaryText from "../../atoms/SecondaryText";
import Tariffs from "../../molecules/Tariffs";

const options = [
    { value: 'restaurant', category: 'cafe', label: 'Ресторан' },
    { value: 'сoffeeРhop', category: 'cafe', label: 'Кафе' },
    { value: 'bar', category: 'cafe', label: 'Бар' },
    { value: 'shop', category: 'market', label: 'Магазин' },
    { value: 'market', category: 'market', label: 'Рынок' },
    { value: 'shoppingСenter', category: 'market', label: 'ТЦ' },
    { value: 'gasStation', category: 'station', label: 'АЗС' },
    { value: 'service', category: 'station', label: 'СТО' },
    { value: 'carWash', category: 'station', label: 'Автомойка' },
    { value: 'beautySalon', category: 'beauty', label: 'Салон красоты' },
    { value: 'medicalCenter', category: 'beauty', label: 'Медцентр' },
    { value: 'barbershop', category: 'beauty', label: 'Барбершоп' },
    { value: 'parking', category: 'parking', label: 'Паркинг' },
    { value: 'parkingPlace', category: 'parking', label: 'Стоянка' },
    { value: 'checkpoint', category: 'parking', label: 'КПП' },
    { value: 'businessCenter', category: 'office', label: 'Бизнес-центр' },
    { value: 'office', category: 'office', label: 'Офис' },
    { value: 'hotel', category: 'hotel', label: 'Отель' },
]

class PriceBlock extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            activeCategory: options[0]
        };
    }
    render(){
        const selectCategory = (cat) => {
            this.setState((state) => {
                return { activeCategory: cat };
            });
        }
        const categoryItem = (item) => {
            return (
                <button
                    className={`${styles.categoryListItem} ${item.value === this.state.activeCategory.value ? styles.categoryListItemActive : ''}`}
                    onClick={() => selectCategory(item)} key={item.value}
                >
                    { item.label }
                </button> 
            )
        }
        return (
            <div className={styles.container}>
                <CenteredContainer>
                    <BlockTitle style={{ textAlign: 'center' }}>Сколько это стоит?</BlockTitle>
                    <div data-aos="fade-down" className={styles.category}>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/1.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(0, 3).map(item => categoryItem(item))}
                            </div>
                        </div>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/2.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(3, 6).map(item => categoryItem(item))}
                            </div>
                        </div>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/3.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(6, 9).map(item => categoryItem(item))}
                            </div>
                        </div>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/4.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(9, 12).map(item => categoryItem(item))}
                            </div>
                        </div>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/5.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(12, 15).map(item => categoryItem(item))}
                            </div>
                        </div>
                        <div className={styles.categoryItem}>
                            <div className={styles.categoryItemIcon}>
                                <img src="./icons/category/6.svg" alt=""/>
                            </div>
                            <div className={styles.categoryList}>
                                { options.slice(15, 18).map(item => categoryItem(item))}
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" className={styles.select}>
                        <Select
                            options={options} onChange={(e) => selectCategory(e)}
                            placeholder={'Выберите категорию'}
                            defaultValue={options[0]}
                        />
                    </div>
                    <Tariffs tariff={this.state.activeCategory} handleContactFormToggle={this.props.handleContactFormToggle} />
                    <SecondaryText center style={{ marginTop: '30px' }}>*сервер на стороне заказчика, одна камера, без учета интеграции</SecondaryText>
                </CenteredContainer>
            </div>
        );
    }
}

export default PriceBlock;