import React from "react";
import styles from "./SystemCapabilitiesBlock.module.css";
import CenteredContainer from "../../molecules/CenteredContainer";
import BlockTitle from "../../atoms/BlockTitle";
import BlockParagraph from "../../atoms/BlockParagraph";

import CapabilityCardsList from "../../molecules/CapabilityCardsList";

class SystemCapabilitiesBlock extends React.Component{
    render(){
        return (
            <CenteredContainer>
                <div className={styles.container} id="capabilities">
                    <BlockTitle centerDesktop={true}>Возможности системы</BlockTitle>
                    <BlockParagraph centerDesktop={true}>Камеры являются глазами компьютера, который анализирует данные без прямого участия человека</BlockParagraph>
                    <CapabilityCardsList/>
                </div>
            </CenteredContainer>
        );
    }
}

export default SystemCapabilitiesBlock;