import React from "react";
import styles from "./CenteredContainer.module.css";
function CenteredContainer(props){
    return (
        <div className={styles.centeredContainer}>
            {props.children}
        </div>
    );
}

export default CenteredContainer;