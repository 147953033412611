import React from "react";
import NavigationBar from "../../organisms/NavigationBar";
import ContactsBlock from "../../organisms/ContactsBlock";
import Footer from "../../organisms/Footer";
import SolutionHeaderBlock from "../../organisms/SolutionHeaderBlock";
import SolutionCapabilitiesBlock from "../../organisms/SolutionCapabilitiesBlock";
import SystemConnectionBlock from "../../organisms/SystemConnectionBlock";
import FullReportingBlock from "../../organisms/FullReportingBlock";
import TheftBlock from "../../organisms/TheftBlock";
import HistorySuccessBlock from "../../organisms/HistorySuccessBlock";
import SolutionPriceBlock from "../../organisms/SolutionPriceBlock";

class SolutionPageTemplate extends React.Component{
    render(){
        return (
            <div>
                <NavigationBar/>
                <SolutionHeaderBlock solution={this.props.solution}/>
                <TheftBlock/>
                <SolutionCapabilitiesBlock solution={this.props.solution}/>
                <FullReportingBlock/>
                <HistorySuccessBlock/>
                <SolutionPriceBlock solution={this.props.solution} handleContactFormToggle={this.props.handleContactFormToggle}/>
                <SystemConnectionBlock/>
                <ContactsBlock handleContactFormToggle={this.props.handleContactFormToggle}/>
                <Footer/>
            </div>
        );
    }
}

export default SolutionPageTemplate;